<template>
  <div class="selector-card__root">
    <NuxtLink :to="card.link">
      <div class="adaptive-img selector-card__img">
        <NuxtImg
          :src="String(card.image)"
          :width="298"
          :height="200"
          :placeholder="true"
          loading="lazy"
          fit="contain"
        />
        <div class="tags">
          <div
            v-for="(tag ,idx) in card.tags"
            :key="`selector-tag-${tag.title}-${idx}`"
            class="tag-border"
            :class="[tagColorText(tag)]"
            :style="{
              '--tag-gradient-from':tag.gradient_from,
              '--tag-gradient-to':tag.gradient_to
            }"
          >
            <div
              class="tag"
            >
              {{ tag.title }}
            </div>
          </div>
        </div>
      </div>
    </NuxtLink>
    <div class="selected-card__body">
      <div class="title">
        <span>{{ card?.title }}</span>
      </div>
      <div
        v-if="card.minPrice"
        class="price"
      >
        <span> Квартиры от {{ $priceFormat({
          amount:card.minPrice
        }) }} ₽ </span>
      </div>
      <div class="divider" />
      <div class="selected-body__items">
        <div
          v-for="(item, idx) in card.features"
          :key="`description-item-${idx}`"
          class="selected-body__item"
        >
          <NuxtImg
            class="icon"
            :src="String(item.icon)"
            placeholder
            width="30"
            height="30"
            loading="lazy"
          />
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
      <div class="show-more__wrapper">
        <NuxtLink
          :to="card.link"
          class="show-more"
        >
          {{ card.button_text }}
        </NuxtLink>
      </div>
      <NuxtLink
        class="absolute-link"
        :to="card.link"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SelectorBlockPropsValuesType } from '~/cms/blocks/SelectorBlock.vue';

const { $priceFormat } = useNuxtApp();

const props = defineProps<{
	card: SelectorBlockPropsValuesType &{
		name:string;
		minPrice?:number;
	};
}>();

const tagColorText = (tag:typeof props.card.tags[0]) => tag.text_color || '';
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.selector-card__root {
  transition: box-shadow .2s linear;
  border-radius: 15px;

  &:hover {
    box-shadow: 0 1px 15px 4px rgb(4 78 146 / 12%);

  }
}

.selector-card__img {
	position: relative;
	border-radius: 15px 15px 0 0;
	overflow: hidden;
	height: 200px;

	& > .tags{
		position: absolute;
		top: 10px;
		left: 10px;
		display: flex;
		align-items: center;
		gap: 5px;
		flex-wrap: wrap;

		& .tag-border{
			padding: 0.5px;
			background: linear-gradient(to bottom, var(--tag-gradient-from, #EFAC00) 100%, var(--tag-gradient-to, #FFCC49) 50%);
			border-radius: 52px;

			&.light{
				& .tag{
					color: var(--white);
				}
			}
		}

		&  .tag{
			font-size: 12px;
			font-weight: 600;
			line-height: 18px;
			letter-spacing: -0.12px;
			border-radius: 52px;
			padding: 3px 12px;
			background: linear-gradient(270deg,var(--tag-gradient-from, #EFAC00) 0%, var(--tag-gradient-to, #FFCC49) 100%);
			color: var(--primary);
		}
	}

	&::before {
		padding-top: 55%;
	}

	& > img {
		width: 100%;
		height: 100%;
		position: absolute;
		inset: 0;
		object-fit: cover;
	}
}

.selected-card__body {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
	padding: 18px 0 24px;
	border-radius: 0 0 15px 15px;
	border: 1.087px solid rgb(226 221 233 / 50%);
	background: var(--white);
	box-shadow: 0 0 0 0 rgb(0 0 0 / 3%), 0 2.2px 4.3px 0 rgb(0 0 0 / 3%), 0 7.6px 7.6px 0 rgb(0 0 0 / 3%), 0 17.3px 9.7px 0 rgb(0 0 0 / 2%), 0 30.4px 11.9px 0 rgb(0 0 0 / 0%), 0 47.8px 13px 0 rgb(0 0 0 / 0%);

	& > .title {
    padding: 0 20px;
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: 110%;
		color: var(--primary);
		margin-bottom: 14px;
	}

	& > .price {
    padding: 0 20px;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 110%; /* 32.073px */
		letter-spacing: -0.36px;
		color: var(--primary-80);
	}

	& > .divider {
		width: 100%;
		height: 1px;
		background-color: var(--primary-15);
		margin: 20px 0;
	}

	& > .show-more__wrapper{
		padding: 0 26px;
    margin-top: auto;
    z-index: 1;
    width: fit-content;
	}

	&  .show-more {
		border-radius: 62px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 26px;
		width: fit-content;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 24.825px; /* 155.153% */
		letter-spacing: -0.32px;
		text-transform: capitalize;
		color: var(--primary);
		background: var(--secondary);
		box-shadow: 0 0 0 0 rgb(0 0 0 / 4%), 0 2.4px 4.9px 0 rgb(0 0 0 / 4%), 0 8.6px 8.6px 0 rgb(0 0 0 / 4%), 0 19.8px 11.17px 0 rgb(0 0 0 / 3%), 0 34.75px 13.65px 0 rgb(0 0 0 / 1%), 0 54.612px 14.89px 0 rgb(0 0 0 / 0%);

		&:hover{
			opacity: .7;
		}
	}
}

.selected-body__items {
	padding: 0 15px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-bottom: 30px;
}

.selected-body__item {
	display: flex;
	gap: 10px;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
	color: var(--primary-80);

	& > .icon{
		min-width: 20px;
		width: 20px;
		height: 20px;
	}
}

.absolute-link {
  z-index: 0;
  position: absolute;
  inset: 0;
}

@include media('lg') {
	.selected-card__body {
		& > .price {
			font-size: 18px;
			line-height: 110%;
		}
	}

	.selected-body__items {
		gap: 12px;
	}

	.selected-body__item {
		font-size: 15px;
		line-height: 115%;
	}
}

@include media('md') {
	.selector-card__img{
		height: 200px;
	}

	.selected-card__body {
		padding: 10px 0 14px;

		& > .title{
			padding: 0 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 110%; /* 30.8px */
			letter-spacing: -1.12px;
			margin-bottom: 8px;
		}

		& > .price {
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			padding: 0 20px;
		}

		& > .divider{
			margin: 15px 0;
		}
	}

.selected-body__items{
	padding: 0 20px;
	gap: 10px;
	margin-bottom: 16px;
}

	.selected-body__item {
		font-size: 15px;
		font-weight: 400;
	}
}

@include media('sm') {
	.selected-body__items{
		gap: 5px;
	}
}
</style>
